import styled from 'styled-components'
import * as colors from 'config/theme/colors'
import Url from 'components/Url'

const Content = styled.div`
  background-color: ${(p) => p.type === 'success' ? p.background : colors.alert()};
  color: ${(p) => p.type !== 'success' && '#fff'};
  padding: 1rem;
  margin-top: 2rem;
  text-align: center;
  width: 100%;
`

const Message = ({ type, text }) => {
  return (
    <Content
      type={type}
      background={process.env.primaryColor}
    >
      <p>{Url({ text, url: '/contact-us' })}</p>
    </Content>
  )
}

export default Message
