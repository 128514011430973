import styled from 'styled-components'
import * as colors from 'config/theme/colors'
import _ from 'lodash'

const ErrorMessageP = styled.p`
  color: ${({ theme }) => (theme.error ? theme.error : colors.alert())};
  line-height: 1.2;
  min-height: 1.2em;
  margin: 0;
`

const ErrorMessage = (props) => {
  const { children } = props
  const parsedChildren = _.isError(children) ? children.message : children
  return <ErrorMessageP {...props} children={parsedChildren} role={'alert'} />
}

export default ErrorMessage
